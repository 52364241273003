import { CTA } from '../../CTA'
import Section from '../../Section/Section'

export default function CTASecCtion({
    title,
    description,
    button,
    phoneNumber,
    phoneHelperText,
    image,
    helperText,
}) {
    return (
        <Section backgroundColor="#0d417d" py={20}>
            <CTA
                title={title}
                description={description}
                primaryBtnTitle={button?.title}
                primaryBtnLink={button?.link}
                image={image}
                phoneNumber={phoneNumber}
                phoneHelperText={phoneHelperText}
                helperText={helperText}
            />
        </Section>
    )
}
